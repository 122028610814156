import React from 'react';
import SongFormContainer from '../song/song_form_container';

const Upload = (props) => {
  return (
    <div>
      <SongFormContainer/>
    </div>
  );
};

export default Upload;
